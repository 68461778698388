.testimonial-swiper {
  width: 100%;
  border-radius: 32px;
  background: linear-gradient(104deg, #fff 1.1%, #fcf7ff 98.41%);
  box-shadow: 2px 2px 10px 0px rgba(169, 61, 253, 0.16);
}

/* .swiper-slide-transform {
  border-radius: 32px;
} */

.industries-swiper {
  width: 100%;

}

.animatedIcon {
  animation: jumpInfinite 1.5s infinite;
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 4px;
  }
  100% {
    margin-top: 0;
  }
}
